
Number.prototype.pad = function(size) {
	//Only run with positive numbers
	var s = String(this);
	while (s.length < (size || 2)) {s = "0" + s;}
	return s;
}
var updateCountDown = function(countDownDate,offset,countdown_message) {
	var now = new Date().getTime () - offset;
	// Find the distance between now and the count down date
	var distance = countDownDate - now;
	// Time calculations for days, hours, minutes and seconds
	var days = Math.floor (distance / (1000 * 60 * 60 * 24));
	var hours = Math.floor (distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
	var minutes = Math.floor (distance % (1000 * 60 * 60) / (1000 * 60));
	var seconds = Math.floor((distance % (1000 * 60)) / 1000);
	current_message = 'DD HH MM SS'
			.replace ('DD', days.pad(2) + 'd')
			.replace ('HH', hours.pad(2) + 'h')
			.replace ('MM', minutes.pad(2) + 'm')
			.replace ('SS', seconds.pad(2) + 's')
			.replace ('00d 00h ', '')
			.replace ('00d ', '');
		// Display the result in the element with id="countDownMessage"
		$ ('#countDownMessage').html (
		  '<span>' +
			countdown_message.replace ('COUNTDOWN', '<span class="countdown-time">' + current_message + "</span>") +
			'</span>'
		);
		// If the count down is finished, write some text
		if (distance < 0) {
		  clearInterval (x);
		  $ ('#countDownMessage').html (
			'<span>' + 'This store is closing now' + '</span>'
		  );
		}
}
var x = null;//interval

var prepareCountDown = function(){
	end_date = DecoWorx.Spar.config.end_date;
	timeServer = DecoWorx.Spar.config.server_time;
	nowServer = new Date(timeServer).getTime();
	/**
	 * //IE 11 not support end_date + ' 23 59 59' string.
	 */
	if (end_date != null && DecoWorx.Spar.config.show_countdown == true) {

		var countDownDate = new Date (end_date);
		var nowClient = new Date().getTime ();
		offset = nowServer - nowClient
		countdown_message = DecoWorx.Spar.config.countdown_message;
	  // Update the count down every 1 minute
	   updateCountDown(countDownDate,offset,countdown_message);
       var x = setInterval (function () {
       updateCountDown(countDownDate,offset,countdown_message);
	 },
        // Get today's date and time
        1000 //1 update per second
      );
    }
 }

 var toggleFundraiserState = function(){
		//Check if 'minimized value exists in sessionstorage and toggle state in sessionStorage accordingly
	if(sessionStorage.getItem('minimized')){
		sessionStorage.getItem('minimized') == 'true' ? sessionStorage.setItem('minimized', false) : sessionStorage.setItem('minimized', true)
	}else{
		sessionStorage.setItem('minimized', true)
	}
  }
  
  var setFundraiserState = function(){
	//Set fundraiser checkbox  according to 'minimized' value in sessionStorage
	sessionStorage.getItem('minimized') == 'false' || !sessionStorage.getItem('minimized') ? $('.fundraiser-checkbox').prop('checked', false) : $('.fundraiser-checkbox').prop('checked', true)
	//Make fundraiser container visible after setting checkbox option
	$('.fundraiser-container').addClass('visible')
  }

jQuery(document).ready(function($) {

	//minimize or expand fundraiser container according to sessionStorage 'minimize' value
	setFundraiserState()

	//Nav Menu Toggle
	$('.navbar-default .navbar-toggle').click(function(event) {
		$(this).toggleClass('active');
		$('.wrapper.menu-open').removeClass('menu-open')
		if ($('.li-search a').hasClass('active')) {
			$('.fa.fa-times.active').removeClass('active')
			$('.fa.fa-bars.hide').removeClass('hide');

		} else {
			$('.fa.fa-times.active').removeClass('active');
			$('.fa.fa-bars.hide').removeClass('hide');
		}
	});
	//Search button toggle
	$('.li-search a').click(function(event) {
		$(this).toggleClass('active');
		$('.search').toggleClass('active');
		if ($('.li-search a').hasClass('active')) {
			$('form.search.active .form-group').css('top', '0px');

			//If top banner is set to show while and search is active
			if($('.home > .top-info-banner #store-info-banner').css('display') == 'block'){
				$('.store-info-banner-contain.top-info-banner').css('margin-top', '60px')//add top margin to compensate for search bar
				$('.navbar-default').css('top', '0');//prevent action buttons from dropping down - not necessary with info banner present 
		      }else{
				$('.navbar-default').css('top', '55px');//add top space to prevent overlapping search bar
				$('.store-info-banner-contain.top-info-banner').css('margin-top', '0')
			}
		} else {
			$('form.search.active .form-group').css('top', '-152px');
			$('.navbar-default').css('top', '0px');
			//If search isn't active - configure showing info banner accordingly
			if($('.home > .top-info-banner #store-info-banner').css('display') == 'block'){
				$('.store-info-banner-contain.top-info-banner').css('margin-top', '0')//Remove top margin - no search present
			}
		}
	});



	/* SEARCH INDEX 2 */
	$('.li-search-b').click(function(event) {
		$(this).hide();
		$(this).parents('.container').css('position','relative');
		$('form.search.b').fadeIn('0.1s');
		$('form.search.b input').focus();
	});


	$('.navbar-collapse').on('show.bs.collapse', function (e) {
  		// do something…
  		e.preventDefault();
		$('.wrapper').toggleClass('menu-open');
		$('button .fa.fa-times').toggleClass('active');
		$('button .fa.fa-bars').toggleClass('hide');
	})

	/* Close bootstrap sidebar */
	$('.navbar-toggle').on('click', function(e){
		e.preventDefault();
		if(!$(this).hasClass('active')){
		      $('button .fa.fa-times').toggleClass('active');
		      $('button .fa.fa-bars').toggleClass('hide');
			$('.wrapper').toggleClass('menu-open');
		}
	})

	/* PRODUCT PAGE FUNCTIONALITY */
	/* Zomm on image */
	$(function(){
    	$('#zoom').okzoom({
        	width: 150,
        	height: 150
    	});
	});

	if ($(window).width() < 768) {
		$('.img-responsive.product').attr('id', '')
	}

	 /* Filter Side Bar Image on click */
	$('ul.side-images li').click(function(event) {
		/* Act on the event */
		// var imagen = $(this).children('img').attr('src');
		var imagen = $(this).children('img').attr('data-large-src');
		$('.img-responsive.product').attr('src',imagen)

		// console.log( "imagen this", this )
	});
	prepareCountDown();
	
	$('.mask-currency').maskCurrency();
});



jQuery(document).on( 'spar-ready', function(event,$) {
	/* update navigation items */
	var basename = window.location.pathname
	basename = basename.substr( basename.lastIndexOf('/') + 1 )

	var navItems = document.querySelectorAll( "[class~='nav-item']")
	for( var i = 0; i < navItems.length; ++i ) {
		var item = navItems[i]
		var link = item.querySelector( "a" )

		if ( link && link.getAttribute( "href" ) === basename ) {
			link[ "href" ] = "#"
			item.className += " active"

			link.textContent += " "
			var span = document.createElement( "span" )
			span.className = "sr-only"
			span.appendChild( document.createTextNode("(current)") )
			link.appendChild( span )

			break
		}
	}

	navItems = document.querySelectorAll( 'footer ul[class~="pages"] a')
	for( var i = 0; i < navItems.length; ++i ) {
		link = navItems[i]
		if ( link.getAttribute( "href" ) === basename ) {
			link[ "href" ] = "#"
		}
	}

  jQuery('head').append('<link href="' + DecoWorx.Spar.config['favicon'] + '" rel="shortcut icon" type="image/x-icon" />');
})

//toggle fundraiser dropdown caret
$('#fundraiser-toggle, #fundraiser-gauge-toggle').change(function() {
	$('.fundraiser-container i').toggleClass('fa-caret-up')

	//When clicking caret to minimize or expand the fundraiser container, store this state in sessionStorage
	toggleFundraiserState()
  });

jQuery(document).ready(function($) {

/*global jQuery */
/*
* FitText.js 1.2
*/

(function( $ ){

	$.fn.fitText = function( kompressor, options ) {
	
		// Setup options
		var compressor = kompressor || 1,
		settings = $.extend({
			'minFontSize' : Number.NEGATIVE_INFINITY,
			'maxFontSize' : Number.POSITIVE_INFINITY
		}, options);
	
		return this.each(function(){
	
		// Store the object
		var $this = $(this);
	
		// Resizer() resizes items based on the object width divided by the compressor * 10
		var resizer = function () {
		$this.css('font-size', Math.max(Math.min($this.width() / (compressor*10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize)));
		};
	
		// Call once to set.
		resizer();
	
		// Call on resize. Opera debounces their resize by default.

		//$(window).on('resize.fittext orientationchange.fittext', resizer);
		$(window).on('resize.fittext click.fittext orientationchange.fittext', resizer);
		});
	};
	})( jQuery );

	/* PRODUCT PAGE FUNCTIONALITY */
	/* Zomm on image */
	$(function(){
		$('#zoom').okzoom({
			width: 150,
			height: 150
		});
	});

	if ($(window).width() < 768) {
		$('.img-responsive.product').attr('id', '')
	}

	//Adjust text to fit within container
	$("#paralax-text-contain h1").fitText(1.4)
	$("#paralax-text-contain p").fitText(2)
});
	